"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEpisodes = exports.getEpisodeNumber = exports.getEpisode = exports.isPostInSeries = exports.getSeriesName = exports.seriesDatabase = void 0;
function _createForOfIteratorHelperLoose(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (it)
    return (it = it.call(o)).next.bind(it); if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
    if (it)
        o = it;
    var i = 0;
    return function () { if (i >= o.length)
        return { done: true }; return { done: false, value: o[i++] }; };
} throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
} return arr2; }
const gatsby_1 = require("gatsby");
exports.seriesDatabase = new Map([["graph-based-generator", "Graph-Based Dungeon Generator"]]);
function getSeriesName(series) { return exports.seriesDatabase.get(series); }
exports.getSeriesName = getSeriesName;
function loadSeriesPosts() { var seriesPosts = new Map(); var data = (0, gatsby_1.useStaticQuery)("225443174"); var seriesPostCounter = new Map(); for (var _iterator = _createForOfIteratorHelperLoose(data.allMdx.nodes), _step; !(_step = _iterator()).done;) {
    var post = _step.value;
    if (!post.frontmatter.series) {
        continue;
    }
    var order = seriesPostCounter.get(post.frontmatter.series) || 0;
    seriesPostCounter.set(post.frontmatter.series, order + 1);
    seriesPosts.set(post.fields.slug, { title: post.frontmatter.title, series: post.frontmatter.series, slug: post.fields.slug, order: order });
} return seriesPosts; }
var cachedPosts = null;
function getPosts() { if (cachedPosts === null) {
    cachedPosts = loadSeriesPosts();
} return cachedPosts; } /**
 * Check if a given post is part of any series.
 */
function isPostInSeries(slug) { return getPosts().has(slug); } /**
* Get episode data of a given post
*/
exports.isPostInSeries = isPostInSeries;
function getEpisode(slug) { var episode = getPosts().get(slug); if (!episode) {
    throw "Post with slug " + slug + " is not part of any series!";
} return episode; } /**
* Get episode number. Starts with 1.
*/
exports.getEpisode = getEpisode;
function getEpisodeNumber(slug) { var episode = getEpisode(slug); return episode.order + 1; }
exports.getEpisodeNumber = getEpisodeNumber;
function getEpisodes(series) { var posts = Array.from(getPosts().values()); var episodes = posts.filter(function (post) { return post.series === series; }); return episodes; }
exports.getEpisodes = getEpisodes;
